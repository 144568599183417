<template>
	<div class="GoodsInfoSSS">
		
		<div class="Top">
			<div class="Left">
				商品详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回商品列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				
				
				<li v-if="Goods.Id != ''" class="Sel">
					<span class="Sel">商品ID</span>
					<em class="Sel">
						{{Goods.Id}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">商品名</span>
					<em  class="Sel">
						<el-input v-model="Goods.Title" style="width: 605px;"></el-input>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">商品分类</span>
					<em  v-if="Goods != null" class="Sel">
						<el-select @change="CategoryOneChange" placeholder="设置一级分类" v-model="Goods.CategoryOne">
								<el-option v-for="cat in CategoryListOne" :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
					    </el-select>
						<i style="display: inline-block;margin-right: 5px;"></i>
						<el-select @change="CategoryTwoChange" v-if="CategoryListTwo.length > 0" placeholder="设置二级分类" v-model="Goods.CategoryTwo">
								<el-option v-for="cat in CategoryListTwo" :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
						</el-select>
						<i style="display: inline-block;margin-right: 5px;"></i>
						<el-select v-if="CategoryListThree.length > 0" placeholder="设置三级分类" v-model="Goods.CategoryThree">
								<el-option v-for="cat in CategoryListThree" :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
						</el-select>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">品牌</span>
					<em  v-if="Goods != null" class="Sel">
							<el-select v-model="Goods.BrandId" remote reserve-keyword filterable :remote-method="RemoteBrandMeth" :loading="RemoteBrandLoading" placeholder="请选择">
								<el-option :label="'无品牌'" :value="''"></el-option>
							    <el-option
							      v-for="item in BrandList"
							      :key="item.Id"
							      :label="item.Name"
							      :value="item.Id">
							    </el-option>
							  </el-select>
							  
							  <i style="margin-left: 10px;">支持搜索全站品牌</i>
					
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">分组(店内分类)</span>
					<em  v-if="Goods != null" class="Sel">
						<el-select placeholder="设置分组" v-model="Goods.ClassificationId">
							<el-option :label="'不设分组'" :value="''"></el-option>
							<el-option :label="classification.Name" :value="classification.Id" :key="classification.Id" v-for="classification in GoodsClassificationList"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">商品主图</span>
					<em  v-if="Goods != null" class="Sel" style="display: flex;">
						
						<div class="PreviewImg" v-if="Goods.Thumb != ''">
							<div class="ShowContent">
								<img :src="Goods.Thumb" class="avatar">
							</div>
							<i class="Remove" @click="removeThumb()" style="color: #FFFFFF;">移除</i>
						</div>
						
						<div class="PreviewImg" v-if="Goods.Thumb == ''">
							<div class="ShowContent">
							 <el-upload 
							   action="https://jsonplaceholder.typicode.com/posts/"
							   :show-file-list="false" :on-change="getFileChange" :limit="1" :auto-upload="false">
							 	<i  class="el-icon-plus avatar-uploader-icon"></i>
							 </el-upload>
							</div>
						</div>
						
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">预览图墙</span>
					<em  v-if="Goods != null" class="Sel" style="display: flex;">
						
						<div class="PreviewImg" v-for="(item,itemI) in PicWall" :key="itemI">
							<div class="ShowContent">
								<img :src="item" class="avatar">
							</div>
							<i class="Remove" @click="removePicWall(itemI)" style="color: #FFFFFF;">移除</i>
						</div>
						
						<div class="PreviewImg" v-if="PicWall.length < 5">
							<div class="ShowContent">
							 <el-upload
							   action="https://jsonplaceholder.typicode.com/posts/"
							   :show-file-list="false" :on-change="getFilesChange" :limit="5" :auto-upload="false">
							 	<i  class="el-icon-plus avatar-uploader-icon"></i>
							 </el-upload>
							</div>
						</div>
						
					</em>
				</li>
				
				
				
				<li class="Sel">
					<span class="Sel">是否支持拼团</span>
					<em  v-if="Goods != null" class="Sel">
						<el-radio v-model="Goods.IsPt" :label="1">支持</el-radio>
						<el-radio v-model="Goods.IsPt" :label="2">不支持</el-radio>
					</em>
				</li>
				
				<li class="Sel" v-if="Goods.IsPt === 1">
					<span class="Sel">成团人数</span>
					<em  class="Sel">
						<el-input-number :precision="0" style="width: 120px;"  v-model="Goods.PtMembers" :min="2" :max="99999999" :controls="false"></el-input-number>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">售价</span>
					<em>
						<el-input-number :precision="2" style="width: 120px;"   v-model="Goods.Price" :min="0.01" :max="999999.99" :controls="false"></el-input-number>
					</em>
					
					<span style="margin-right: 19px;margin-left: 10px;color: rgba(0,0,0,0.4);">原价</span>
					<em >
						<el-input-number :precision="2" style="width: 120px;"   v-model="Goods.OriginalPrice" :min="0.01" :max="999999.99" :controls="false"></el-input-number>
					</em>
					
					<template v-if="Goods.IsPt === 1">
					<span style="margin-right: 23px;margin-left: 10px;color: rgba(0,0,0,0.4);">拼团价</span>
					<em  class="Sel">
						<el-input-number :precision="2" style="width: 120px;"   v-model="Goods.PricePt" :min="0.01" :max="999999.99" :controls="false"></el-input-number>
					</em>
					</template>
				</li>
				
				<li class="Sel">
					<span class="Sel">单位</span>
					<em>
						<el-input  v-model="Goods.Unit" style="width: 120px;text-align: center;"></el-input>
					</em>
					
					<span style="margin-right: 10px;margin-left: 10px;color: rgba(0,0,0,0.4);">重(克)</span>
					<em>
						<el-input-number :precision="0" style="width: 120px;"   v-model="Goods.Weight" :min="1" :max="99999999" :controls="false"></el-input-number>
					</em>
					
					<span style="margin-right: 10px;margin-left: 10px;color: rgba(0,0,0,0.4);">运费模板</span>
					<em>
						<el-select size="mini" v-model="Goods.ExpressFeeTemplateId">
							<el-option :value="''" :label="'零运费'"></el-option>
							<el-option v-for="item in ExpressFeeTemplateList" :key="item.Id" :value="item.Id" :label="item.Name+(item.RuleType == 'weight' ? '(按重量)':'(按件数)')"></el-option>
						</el-select>
					</em>
					
				</li>
				
				
				<li class="Sel">
					<span class="Sel">库存数</span>
					<em  class="Sel">
						<el-input-number :precision="0" style="width: 120px;"  v-model="Goods.Amount" :min="0" :max="99999999" :controls="false"></el-input-number>
					</em>
				</li>

				<li class="Sel">
					<span class="Sel">属性/规格/价格</span>
					<em  class="Sel">
						<GoodsSpecEdit v-if="finishAttrAndSku" :goods="Goods" @UpSkuList="UpSkuList" :attrs="AttrList" :skus="SkuList"></GoodsSpecEdit>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">来源商品编号</span>
					<em  class="Sel">
						<el-input v-model="Goods.SourceSn" style="width: 300px;"></el-input>
					</em>
				</li>
				
				
				<li class="Sel">
					<span class="Sel">描述</span>
					<em  class="Sel" style="display: block;min-height: 400px;">
						<GoodsContent :content="this.Content" @UpGoodsContent="UpGoodsContent"></GoodsContent>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">商品状态</span>
					<em  v-if="Goods != null" class="Sel">
						<el-select disabled placeholder="设置状态" v-model="Goods.Status">
							<el-option label="发布待上架" :value="50"></el-option>
							<el-option label="上架审核中" :value="55"></el-option>
						    <el-option label="上架中" :value="60"></el-option>
							<el-option label="补货中" :value="70"></el-option>
							<el-option label="已下架" :value="80"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li v-if="Goods != null && Goods.Id != ''" class="Sel">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{Goods.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Goods != null && Goods.Id != ''" class="Sel">
					<span class="Sel">最后修改时间</span>
					<em class="Sel">
						{{Goods.UpdatedAt}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel"></span>
					<em class="Sel">
						<el-button v-if="Goods != null && Goods.Id != ''" @click="UpdateCheck()" type="danger">提交修改</el-button>
						<el-button v-if="Goods != null && Goods.Id == ''" @click="UpdateGoods()" type="danger">添加商品</el-button>
					</em>
				</li>
			</div>
		</div>
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload,InputNumber,Radio} from 'element-ui'
	import GoodsSpecEdit from '@/components/goods/SpecEdit.vue'
	import GoodsContent from '@/components/goods/GoodsContent.vue'
	export default {
	  name: 'GoodsInfo',
	  props: {
	  },
	  data() {
	      return {
			  Goods:{
				  Id:'',
				  Title:'',
				  Status:50,
				  ClassificationId:'',
				  CategoryOne:'',
				  CategoryTwo:'',
				  CategoryThree:'',
				  Thumb:'',
				 Price:0.00,
				 PricePt:0.01,
				 IsPt:2,
				 Weight:1,//重量 单位 克
				 PtMembers:2,
				 OriginalPrice:0.00,
				  Unit:'',
				  Amount:1,
				  Source:'zy',
				  SourceSn:'',
				  Detail:'',
				  ExpressFeeTemplateId:'',
			  },
			  FatherList:[],
			  AttrList:[],//商品属性
			  BrandList:[],
			  CategoryListOne:[],
			  CategoryListTwo:[],
			  CategoryListThree:[],
			  Content:'',
			  uploadModel:'single',//multiple 多个
			  dialogImageUrl: '',
			  dialogVisible: false,
			  PicWall:[],//图墙
			  SkuList:[],
			  GoodsClassificationList:[],//店内商品分组
			  finishAttrAndSku:false,//加载完数据后再初始化子组件
			  RemoteBrandLoading:false,//远程品牌LOADING
			  ExpressFeeTemplateList:[],
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
		'el-input-number':InputNumber,
		'el-radio':Radio,
		GoodsSpecEdit,
		GoodsContent
	  },
	  created() {
		this.finishAttrAndSku = false
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少商品ID，页面无法工作')
			return
		}
		this.GetGoodsClassificationList()
		 this.GetExpressEeeTemplateList()
		this.GetCategoryList(1,"")
		if(this.$route.params.Id == 'add'){
			this.finishAttrAndSku = true
			return
		}
		this.GetGoods(this.$route.params.Id)
	  },
	  methods:{
		  UpdateCheck(){
		  	
			this.$confirm('商品提交编辑后需要重新申请审核上架, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			  }).then(() => {
				this.UpdateGoods()
			  })
		  },
		  GetExpressEeeTemplateList(){
			  let data = {
			  	Service:'Shop',
			  	Class: 'ExpressFeeTemplate',
			  	Action: 'List',
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	this.ExpressFeeTemplateList = res.Data.ExpressFeeTemplateList
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		  getFilesChange(file, fileList) {
			  this.uploadModel = 'multiple'
				this.getBase64(file.raw).then(res => {
					this.uploadPic(res)
				});
		  },
		  getFileChange(file, fileList) {
			  console.log('准备传图')
			this.uploadModel = 'single'
			this.getBase64(file.raw).then(res => {
				this.uploadPic(res)
			});
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
		  			  uploadPic(_b64){
		  				  let that = this
		  				  let data = {
		  				  	Service:'Goods',
		  				  	Class: 'File',
		  				  	Action: 'Base64Upload',
		  				  	Base64:_b64
		  				  }
		  				  this.$post(that.$store.getters.getApiHost,data)
		  				  .then((res) => {
		  				  	
		  				  	if(res.ErrorId != 0){
		  				  		that.$message(res.Msg)
		  				  		return
		  				  	}
		  				  	
							if(that.uploadModel == 'single'){
								that.Goods.Thumb = res.Data.Url
								if(that.PicWall.length < 5){ //如果图墙未满，则将主图添加到图墙
									that.PicWall.push(res.Data.Url)
								}
							}else if(that.uploadModel == 'multiple'){
								if(that.PicWall.length < 5){
									that.PicWall.push(res.Data.Url)
								}else{
									console.log('图墙超限，最大支持5张')
								}
							}
		  				  	
		  				  })
		  				  .catch(function (response) {
		  				  	that.DialogMsg = '网络请求错误'
		  				  })
		  			  },
			removePicWall(_index){
				this.PicWall.splice(_index,1)
			},
			removeThumb(){
				this.Goods.Thumb = ''
			},
			GetGoodsClassificationList(_page = 1){
				let data = {
					Service:'Shop',
					Class: 'GoodsClassification',
					Action: 'List',
					Page:_page,
					PageSize:20,
					ShopId:this.$store.getters.getShopId,
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					this.GoodsClassificationList = res.Data.GoodsClassificationList
					
				})
				.catch(function (response) {
					this.$message('网络请求错误')
				})
			},
		    GetGoods(_id){
		  		let data = {Service:'Goods',Class: 'Goods',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Goods = res.Data
		  			this.Content = res.Data.Detail
					
					//构建分类
					if(this.Goods.CategoryTwo != ''){
						let catTwo = {
							Id:this.Goods.CategoryTwo,
							Name:this.Goods.CategoryTwoName
						}
						this.CategoryListTwo.push(catTwo)
					}
				
					if(this.Goods.CategoryThree != ''){
						let catThree = {
							Id:this.Goods.CategoryThree,
							Name:this.Goods.CategoryThreeName
						}
						this.CategoryListThree.push(catThree)
					}
				
					
					//构建品牌列表
					if(this.Goods.BrandId.length > 0){
						let brand = {
							Name:this.Goods.BrandName,
							Id:this.Goods.BrandId,
						}
						this.BrandList.push(brand)
					}
					
					this.GetCategoryList(1,'')
					this.PicWall = res.Data.PicWall
					
					this.SkuList = res.Data.SkuList
					this.AttrList = res.Data.AttrList
					this.finishAttrAndSku = true
					
		  		})
		    },
			handleRemove(file, fileList) {
			    console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},	  
			UpdateGoods(){
				let that = this
				
				if(that.AttrList.length == 0){
					that.SkuList = []
				}
				
				let data = {
					Service:'Goods',
					Class: 'Goods',
					Action: 'Update',
					Id:that.Goods.Id,
					Title:that.Goods.Title,
					Thumb:that.Goods.Thumb,
					Unit:that.Goods.Unit,
					Amount:that.Goods.Amount,
					BrandId:that.Goods.BrandId,
					ClassificationId:that.Goods.ClassificationId,
					IsPt:that.Goods.IsPt,
					PtMembers:that.Goods.PtMembers,
					Weight:that.Goods.Weight,
					Price:that.Goods.Price,
					PricePt:that.Goods.PricePt,
					OriginalPrice:that.Goods.OriginalPrice,
					Source:that.Goods.Source,
					SourceSn:that.Goods.SourceSn,
					CategoryOne:that.Goods.CategoryOne,
					CategoryTwo:that.Goods.CategoryTwo,
					CategoryThree:that.Goods.CategoryThree,
					PicWall:JSON.stringify(that.PicWall),
					Detail:that.Content,
					AttrList:JSON.stringify(that.AttrList),
					SkuList:JSON.stringify(that.SkuList),
					Status:that.Goods.Status,
					ExpressFeeTemplateId:that.Goods.ExpressFeeTemplateId
				}
				if(that.Goods.Id == ''){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					if(this.Goods.Id == ''){
						this.GetGoods(res.Data.Id)
					}else{
						this.GetGoods(that.Goods.Id)
					}
				
				})
			},
			UpGoodsContent(_val){
				this.Content = _val
			},
			UpSkuList(_val){
				this.SkuList = _val
			},
			CategoryOneChange(_categoryOne){
				this.Goods.CategoryTwo = ""
				this.CategoryListTwo = []
				this.Goods.CategoryThree = ""
				this.CategoryThree = []
				//读取当前一级分类下的所有二级分类
				this.GetCategoryList(2,_categoryOne)
			},
			CategoryTwoChange(_categoryTwo){
				this.Goods.CategoryThree = ""
				this.CategoryThree = []
				//读取当前一级分类下的所有二级分类
				this.GetCategoryList(3,_categoryTwo)
			},
			GetCategoryList(_level,_fatherId){
	
					let data = {
						Service:'Goods',
						Class: 'GoodsCategory',
						Action: 'List',
						Level:_level,
						Page:1,
						PageSize:300,
						FatherId:_fatherId,
					}
					this.$post(this.$store.getters.getApiHost,data)
					.then((res) => {
						if(res.ErrorId != 0){
							this.$message(res.Msg)
							return
						}
						
						if(_level == 1){
							
							this.CategoryListOne = res.Data.CategoryList
							if(res.Data.CategoryList.length > 0){
									
									this.GetCategoryList(2,this.Goods.CategoryOne)
									
							}
							
						}else if(_level == 2 && this.Goods.CategoryOne != ''){
							
							this.CategoryListTwo = res.Data.CategoryList
							
							if(res.Data.CategoryList.length > 0){
								
								this.GetCategoryList(3,this.Goods.CategoryTwo)
								
							}
							
						}else if(_level == 3 && this.Goods.CategoryTwo != ''){
							this.CategoryListThree = res.Data.CategoryList
						}
					})
					.catch(function (response) {
						this.$message('网络请求错误')
					})
			},
			RemoteBrandMeth(query) {
				
				if(query == ''){
					return
				}
				this.RemoteBrandLoading = true
				let data = {Service:'Brand',Class: 'Brand',Action: 'List',Name:query,Page:1,PageSize:10}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					this.RemoteBrandLoading = false
					if(res.ErrorId != 0){
						return
					}
					this.BrandList = res.Data.BrandList
					
				})
				.catch(function (response) {
					this.RemoteBrandLoading = false
					this.$message('网络请求错误')
				})
			  },
	  }
	}
</script>

<style>
.GoodsInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.GoodsInfoSSS .Top{
	display: flex;
	align-items: center;
}
.GoodsInfoSSS .Top .Left{
	
}
.GoodsInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.GoodsInfoSSS .List{
	margin-top: 20px;	
}
.GoodsInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.GoodsInfoSSS .List .One li.Sel{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.GoodsInfoSSS .List .One li.Sel span.Sel{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.GoodsInfoSSS .List .One li.Sel  em.Sel{
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  
.ql-editor{
        height:360px;
    }
	
	.PreviewImg{
		display: inline-block;
		width: 150px;
		height: 150px;
		border:1px dotted rgba(0,0,0,0.1);
		position: relative;
		margin-right: 5px;
	}
	.PreviewImg:hover{
		border:1px dotted rgba(228,0,0,0.7);
	}
	.PreviewImg i.Remove{
		display: none;
	}
	.PreviewImg:hover i.Remove{
		display: inline-block;
		position: absolute;
		bottom: 0px;
		right: 0px;
		background-color: rgba(228,0,0,0.8);
		padding: 2px 5px;
		border-radius: 2px;
		color: #FFFFFF;
		cursor: pointer;
		font-style: normal;
	}
	.PreviewImg .ShowContent{
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	}
	.PreviewImg .ShowContent img{
		width: 90%;
		height: 90%;
	}
</style>
