<template>
<div class="SpecEdit">
	
	
	<div class="Attr">
		
		<div style="margin-top: 10px;margin-bottom: 10px;display: flex;align-items: center;">
			<el-button style="width: 140px;" size="mini" type="primary" icon="el-icon-plus"  @click="AddAttr()">添加属性</el-button>
			<ul style="margin-left: 10px;font-size: 12px;color: rgba(0,0,0,0.4);">
				价格为0或库存为0的Sku商品将无法被下单、被拼团；若Sku商品未设独立重量，将使用主商品重量；同一类商品拼团在属性时也能拼成功
			</ul>
		</div>
		
		<li v-for="(attr,attrI) in attrs" :key="attrI">
			<span class="Name">
				属性
			</span>
			<span>
				<el-input @input="AttrInput" @change="AttrChange" size="mini" v-model="attr.Name"></el-input>
			</span>
			<span>
				属性值
			</span>
			<span v-for="(attrVal,attrValI) in attr.Vals" :key="attrValI">
				<el-input @input="AttrInput" @change="AttrChange" size="mini" v-model="attrVal.Name"></el-input>
				<i @click="DelAttrVal(attr,attrValI)" class="el-icon-circle-close"></i>
			</span>
			<em>
				<el-button type="primary" icon="el-icon-plus" size="mini" circle @click="AddAttrVal(attr)"></el-button>
				<el-button  icon="el-icon-minus" size="mini" circle @click="DelAttr(attr,attrI)"></el-button>
			</em>
		</li>
	</div>
	
	<div class="Sku" v-if="SkuList.length > 0">
		<div class="Left">
			<li v-for="(sku,skuI) in SkuList" :key="skuI">
				<span>
					规格：{{sku.Name}}
				</span>
			</li>
		</div>
		<div class="Right">
			<li v-for="(sku,skuI) in SkuList" :key="skuI">
				<span>
					售价
				</span>
				<span>
					<el-input-number @change="SkuChange" :precision="2" style="width: 90px;" size="mini" v-model="sku.Price" :min="0.00" :max="999999.99" :controls="false"></el-input-number>
				</span>
				<template v-if="goods.IsPt === 1">
					<span>
						拼团价
					</span>
					<span>
						<el-input-number @change="SkuChange" :precision="2" style="width: 90px;" size="mini" v-model="sku.PricePt" :min="0.00" :max="999999.99" :controls="false"></el-input-number>
					</span>
				</template>
				
				<span>
					重(克)
				</span>
				<span>
					<el-input-number :precision="0" style="width: 60px;" size="mini" v-model="sku.Weight" :min="0" :max="99999999" @change="SkuChange" :controls="false"></el-input-number>
				</span>
				
				<span>
					库存
				</span>
				<span>
					<el-input-number :precision="0" style="width: 60px;" size="mini" v-model="sku.Stock" :min="0" :max="99999999" @change="SkuChange" :controls="false"></el-input-number>
				</span>
				<span>
					第三方Sku
				</span>
				<span>
					<el-input size="mini" style="width: 110px;" @change="SkuChange" v-model="sku.ThirdSkuId"></el-input>
				</span>
			</li>
		</div>
		
	</div>
	
</div>
</template>

<script>
	import uuidv1 from 'uuid/v1'
	import {InputNumber} from "element-ui"
	export default {
	  name: 'GoodsSpecEdit',
	  props: {
		attrs:{
			type:Array,
			default:function(){
				return []
			}
		},
		skus:{
			type:Array,
			default:function(){
				return []
			}
		},
		goods:{
			type:Object,
			default:function(){
				return {}
			}
		},
	  },
	  data() {
	      return {
			  SkuList:[],
			  LastSkuList:[],//上一次完整的SKU列表
	      }
	    },
		components: {
			"el-input-number":InputNumber
		},
		created() {
			this.SkuList = JSON.parse(JSON.stringify(this.skus))
		},
		methods:{
			
			AddAttr(){
				let attr = {
					Id:uuidv1(),
					Name: '',
					Vals: [
						{
							Name: '',
							Id: uuidv1()
						},
					]
				}
				this.attrs.push(attr)
				this.ParseSkuList()
			},
			DelAttr(_attr,_index){
				this.$confirm('是否确定删除'+_attr.Name, '删除提醒', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					this.attrs.splice(_index,1)
					this.ParseSkuList()
				}).catch(() => {
					  this.$message({
						type: 'info',
						message: '已取消删除'
					  });          
				});
			},
			AddAttrVal(attr){
				let attrVal = {
						Name: '',
						Id: uuidv1()
					}
				attr.Vals.push(attrVal)
			},
			DelAttrVal(_attr,_index){
				_attr.Vals.splice(_index,1)
				this.ParseSkuList()
			},
			AttrInput(){
				this.ParseSkuList()
			},
			AttrChange(){
				this.ParseSkuList()
			},
			ParseSkuList(){
				
				this.LastSkuList = JSON.parse(JSON.stringify(this.SkuList))
				this.SkuList = []
			
				let arrParseList = []
				if(this.attrs.length == 1){
					//只有单组属性时，直接得出数据
					this.attrs[0].Vals.forEach((val,index)=>{
						let temp = []
						temp.push(val)
						arrParseList.push(temp)
					})
					
				}else{
					//笛卡尔积递归得出所有属性组合  [[{…}, {…}, {…}],[{…}, {…}, {…}]]
					arrParseList = this.arrp(JSON.parse(JSON.stringify(this.attrs)))
					
				}
				
				if(arrParseList.length == 0){
					return
				}
				
				arrParseList.forEach((vals,index)=>{
					
					let skuName = ""
					let skuAttrIds = []
					vals.forEach((val,index1)=>{
						skuName = skuName + val.Name
						skuAttrIds.push(val.Id)
					})
				
					
					let sku = {
						Name:skuName,
						Price:0.00,//售价
						PricePt:0.01,//拼团价
						OriginalPrice:0.00,//原价
						Weight:1,//重量 克
						Stock:0,
						AttrValIds:skuAttrIds, //属性值ID列表
						ThirdSkuId:'',//第三方反射skuID，用于跟第三方库存对接
						Id:uuidv1(),//系统内部SKUID
					}
					//判断此IDS清单是否存在于旧列表中
					let oldSku = this.GetSameSkuInOldList(skuAttrIds)
					if(oldSku != null){
						sku = oldSku //提取价格等旧SKU属性
						sku.Name = skuName
					}
					
					this.SkuList.push(sku)
					
				})
				
				this.$emit("UpSkuList",this.SkuList)
				
			},
			SkuChange(){
				this.$emit("UpSkuList",this.SkuList)
			},
			GetSameSkuInOldList(_attrValIds){ //从旧SKU列表中取出信息属性值ID完全一致的sku，用于库存价格等赋值
				for(var i=0;i<this.LastSkuList.length;i++){
					
					let oldSku = this.LastSkuList[i]
					
					//如果属性值IDS条数不一样，则跳过
					if(_attrValIds.length != oldSku.AttrValIds.length){
						continue
					}

					let isIn = true
					for(var j=0;j<_attrValIds.length;j++){
						if(oldSku.AttrValIds.find(t =>t == _attrValIds[j] ) == undefined){
							isIn = false
							break
						}
					}
					
					if(isIn){
						return oldSku
					}
					
				}
				
				return null
				
			},
			arrp(arr){
				
			
				//编辑原数组格式
				if(arr.length == 0){
					return []
				}
				if(arr[0].Vals){ //如果传入的组存在首元素及
					arr = arr.map((item)=>{ //将属性组内的所有属性列表提权
						return item=item.Vals
					})
				}
				
				if(arr.length == 1){ //如果当前传入的数组只有一条数据
					//最终合并成一个
					return arr[0];
				}
				
				let arrySon = [];
				//将组合放到新数组中
				arr[0].forEach((item,index)=>{ 
					arr[1].forEach((item1,index1)=>{
						arrySon.push([].concat(arr[0][index],arr[1][index1]));
					})
				})
				// 新数组并入原数组,去除合并的前两个数组
				arr[0] = arrySon;
				arr.splice(1,1);
				// 递归
				return this.arrp(arr)
				
			}
	  }
	}
</script>

<style  scoped>
	.SpecEdit .Attr{
		border-top: 1px dotted rgba(0,0,0,0.2);
		border-bottom: 1px dotted rgba(0,0,0,0.2);
	}
	.SpecEdit .Attr li{
		display: flex;
		align-items: center;
		padding: 10px 0px;
	}
	.SpecEdit .Attr li span{
		display: inline-block;
		margin-right: 10px;
		color: #999999;
		font-size: 12px;
		max-width: 100px;
		display: flex;
		flex-wrap: nowrap;
	}
	.SpecEdit .Attr li span i{
		font-size: 1.1rem;
		cursor: pointer;
	}
	.SpecEdit .Attr li span.Name{
	}
	.SpecEdit .Attr li em{
		flex: 1;
	}
	
	.Sku{
		background-color: rgba(0,0,0,0.04);
		padding: 10px;
		display: flex;
	}
	.Sku .Left{
		
	}
	.Sku .Right{
		flex: 1;
	}
	.Sku li{
		display: flex;
		padding: 10px;
		align-items: center;
		font-size: 0.9rem;
		color: rgba(0,0,0,0.4);
		height: 25px;
		line-height: 25px;
	}
	.Sku li span{
		display: inherit;
		margin-right: 10px;
	}
	.Sku li span.Name{
		width: 200px;
		text-align: right;
		overflow: hidden;
	}
</style>
